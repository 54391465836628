<template>
  <div class="inspection-handler">
    <!-- Inspection List Modal -->
    <div v-if="showInspectionList" class="modal-overlay" @click="showInspectionList = false">
      <div class="modal-content" @click.stop>
        <div class="modal-header">
          <h3>Prüfungen laden</h3>
          <button class="close-btn" @click="showInspectionList = false">×</button>
        </div>

        <div class="inspection-list">
          <div v-if="isLoading" class="loading-state">
            <div class="spinner"></div>
            <p>Lade Prüfungen...</p>
          </div>
          
          <div v-else-if="inspections.length === 0" class="empty-state">
            <span class="empty-icon">📋</span>
            <p>Keine Prüfungen vorhanden</p>
          </div>
          
          <div v-else class="inspection-items">
            <div 
              v-for="inspection in inspections" 
              :key="inspection.id"
              class="inspection-item"
              @click="loadInspection(inspection)"
            >
              <div class="inspection-main">
                <span class="inspection-icon" :class="inspection.type">
                  {{ getInspectionIcon(inspection.type) }}
                </span>
                <div class="inspection-info">
                  <span class="inspection-type">{{ getInspectionTypeName(inspection.type) }}</span>
                  <div class="inspection-meta">
                    <span class="inspection-date">{{ formatDate(inspection.date) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- New Inspection Modal -->
    <div v-if="showNewInspectionModal" class="modal-overlay" @click="showNewInspectionModal = false">
      <div class="modal-content" @click.stop>
        <div class="modal-header">
          <h3>Neue Prüfung erstellen</h3>
          <button class="close-btn" @click="showNewInspectionModal = false">×</button>
        </div>

        <div class="modal-body">
          <!-- Inspection Form -->
          <div class="form-group">
            <label>Prüfungsdatum</label>
            <input 
              v-model="newInspection.date" 
              type="date" 
              required
            >
          </div>

          <div class="form-group">
            <label>Art der Prüfung</label>
            <select v-model="newInspection.type" required>
              <option value="haupt">Hauptprüfung</option>
              <option value="sonder">Sonderprüfung</option>
              <option value="unter">Untersuchung</option>
              <option value="neben">Nebenprüfung</option>
            </select>
          </div>

          <div class="form-group">
            <label>PDF auswählen</label>
            <div class="pdf-selection-list">
              <div 
                v-for="pdf in projectPdfs" 
                :key="pdf.id"
                class="pdf-selection-item"
                :class="{ selected: newInspection.pdfId === pdf.id }"
                @click="newInspection.pdfId = pdf.id"
              >
                <span class="pdf-icon">📄</span>
                <span class="pdf-name">{{ pdf.filename }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button class="cancel-btn" @click="showNewInspectionModal = false">
            Abbrechen
          </button>
          <button class="submit-btn" @click="createNewInspection" :disabled="!isInspectionFormValid">
            Prüfung erstellen
          </button>
        </div>
      </div>
    </div>

    <!-- PDF Viewer Container -->
    <div v-if="showPdfViewer" class="pdf-viewer-wrapper">
      <PdfViewer
        :pdf-url="currentPdfUrl"
        :inspection="currentInspection"
        @close="closePdfViewer"
        @marker-added="saveMarker"
        @marker-updated="updateMarker"
        @marker-deleted="deleteMarker"
      />
      
      <!-- Marker Popup -->
      <PopUpMarker
        v-if="selectedMarker"
        :marker="selectedMarker"
        @close="closeMarkerPopup"
        @save="saveMarkerDetails"
        @delete="deleteMarker"
      />

      <!-- Camera Popup -->
      <CameraPopup
        v-if="showCamera"
        @capture="onPhotoTaken"
        @close="showCamera = false"
      />
    </div>
  </div>
</template>

<script>
import PdfViewer from './PdfViewer.vue'
import PopUpMarker from './PopUpMarker.vue'
import CameraPopup from './CameraPopup.vue'

export default {
  name: 'HandlerInspection',
  
  components: {
    PdfViewer,
    PopUpMarker,
    CameraPopup
  },

  props: {
    projectId: {
      type: Number,
      required: true
    },
    projectPdfs: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      showNewInspectionModal: false,
      showPdfViewer: false,
      newInspection: {
        date: new Date().toISOString().split('T')[0],
        type: 'haupt',
        pdfId: null
      },
      currentInspection: null,
      currentPdfUrl: null,
      selectedMarker: null,
      showCamera: false,
      markers: [],
      showInspectionList: false,
      inspections: [],
      isLoading: false
    }
  },

  computed: {
    isInspectionFormValid() {
      return this.newInspection.date && 
             this.newInspection.type && 
             this.newInspection.pdfId;
    }
  },

  methods: {
    async createNewInspection() {
      try {
        if (!this.newInspection.pdfId) {
          alert('Bitte wählen Sie eine PDF aus');
          return;
        }

        // Find the selected PDF from projectPdfs
        const selectedPdf = this.projectPdfs.find(pdf => pdf.id === this.newInspection.pdfId);
        if (!selectedPdf) {
          throw new Error('Selected PDF not found');
        }

        // Create inspection data
        const inspectionData = {
          date: this.newInspection.date,
          type: this.newInspection.type,
          pdfId: this.newInspection.pdfId,
          projectId: this.projectId
        };

        console.log('Creating inspection:', inspectionData);

        // Store in localStorage
        localStorage.setItem('currentInspection', JSON.stringify({
          ...inspectionData,
          pdf: selectedPdf
        }));

        // Setup PDF viewer with URL
        this.currentInspection = inspectionData;
        this.currentPdfUrl = `${process.env.VUE_APP_API_URL}/FastAPI/projects/${this.projectId}/pdfs/${selectedPdf.id}/download`;

        // Download and store PDF in localStorage
        const pdfResponse = await fetch(this.currentPdfUrl);
        const pdfBlob = await pdfResponse.blob();
        const reader = new FileReader();
        
        reader.onloadend = () => {
          localStorage.setItem(`pdf_${selectedPdf.id}`, reader.result);
          localStorage.setItem(`pdf_${selectedPdf.id}_timestamp`, Date.now());
          
          // Initialize markers array for this inspection
          this.markers = [];
          localStorage.setItem(`markers_${this.currentInspection.id}`, JSON.stringify([]));
          
          this.showNewInspectionModal = false;
          this.showPdfViewer = true;
        };

        reader.readAsDataURL(pdfBlob);

      } catch (error) {
        console.error('Error creating inspection:', error);
        alert('Fehler beim Erstellen der Prüfung');
      }
    },

    closePdfViewer() {
      this.showPdfViewer = false;
      this.currentInspection = null;
      this.currentPdfUrl = null;
    },

    // Add method to check if there's a saved inspection
    checkSavedInspection() {
      const savedInspection = localStorage.getItem('currentInspection');
      if (savedInspection) {
        const inspectionData = JSON.parse(savedInspection);
        // Verify if the saved inspection belongs to current project
        if (inspectionData.projectId === this.projectId) {
          this.currentInspection = inspectionData;
          this.currentPdfUrl = `${process.env.VUE_APP_API_URL}/FastAPI/projects/${this.projectId}/pdfs/${inspectionData.pdfId}/download`;
          this.showPdfViewer = true;
        }
      }
    },

    cleanupOldPdfs() {
      // Keep only the last 5 PDFs in localStorage
      const MAX_STORED_PDFS = 5;
      const pdfKeys = Object.keys(localStorage)
        .filter(key => key.startsWith('pdf_'))
        .sort((a, b) => {
          const timeA = localStorage.getItem(`${a}_timestamp`);
          const timeB = localStorage.getItem(`${b}_timestamp`);
          return timeB - timeA;
        });

      if (pdfKeys.length > MAX_STORED_PDFS) {
        pdfKeys.slice(MAX_STORED_PDFS).forEach(key => {
          localStorage.removeItem(key);
          localStorage.removeItem(`${key}_timestamp`);
        });
      }
    },

    // Marker handling methods
    saveMarker(markerData) {
      const marker = {
        id: Date.now(), // Temporary ID
        ...markerData,
        inspection_id: this.currentInspection.id
      };
      
      this.markers.push(marker);
      localStorage.setItem(`markers_${this.currentInspection.id}`, JSON.stringify(this.markers));
      this.selectedMarker = marker;
    },

    updateMarker(updatedMarker) {
      const index = this.markers.findIndex(m => m.id === updatedMarker.id);
      if (index !== -1) {
        this.markers[index] = updatedMarker;
        localStorage.setItem(`markers_${this.currentInspection.id}`, JSON.stringify(this.markers));
      }
      this.selectedMarker = null;
    },

    deleteMarker(markerId) {
      this.markers = this.markers.filter(m => m.id !== markerId);
      localStorage.setItem(`markers_${this.currentInspection.id}`, JSON.stringify(this.markers));
      this.selectedMarker = null;
    },

    closeMarkerPopup() {
      this.selectedMarker = null;
    },

    // Camera handling
    onPhotoTaken(photoData) {
      if (this.selectedMarker) {
        if (!this.selectedMarker.images) {
          this.selectedMarker.images = [];
        }
        this.selectedMarker.images.push(photoData);
        this.updateMarker(this.selectedMarker);
      }
      this.showCamera = false;
    },

    async fetchInspections() {
      this.isLoading = true;
      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_URL}/FastAPI/projects/${this.projectId}/inspections`
        );
        
        if (!response.ok) throw new Error('Failed to fetch inspections');
        
        this.inspections = await response.json();
      } catch (error) {
        console.error('Error fetching inspections:', error);
        alert('Fehler beim Laden der Prüfungen');
      } finally {
        this.isLoading = false;
      }
    },

    async loadInspection(inspection) {
      try {
        // Get PDF info
        const pdf = this.projectPdfs.find(p => p.id === inspection.pdf_id);
        if (!pdf) throw new Error('PDF not found');

        // Load markers for this inspection
        let markers = [];
        try {
          const markersResponse = await fetch(
            `${process.env.VUE_APP_API_URL}/FastAPI/inspections/${inspection.id}/markers`
          );
          
          if (markersResponse.ok) {
            markers = await markersResponse.json();
          } else {
            console.warn('Failed to load markers, continuing with empty list');
          }
        } catch (markerError) {
          console.warn('Error loading markers:', markerError);
          // Continue with empty markers list
        }

        // Store complete inspection data in localStorage
        const inspectionData = {
          ...inspection,
          pdf: pdf,
          markers: markers
        };
        
        localStorage.setItem('currentInspection', JSON.stringify(inspectionData));

        // Setup PDF viewer
        this.currentInspection = inspectionData;
        this.currentPdfUrl = `${process.env.VUE_APP_API_URL}/FastAPI/projects/${this.projectId}/pdfs/${pdf.id}/download`;
        
        // Close list and show viewer
        this.showInspectionList = false;
        this.showPdfViewer = true;

      } catch (error) {
        console.error('Error loading inspection:', error);
        alert('Fehler beim Laden der Prüfung');
      }
    },

    getInspectionIcon(type) {
      const icons = {
        'haupt': '🔍',
        'sonder': '🔎',
        'unter': '📋',
        'neben': '📝'
      };
      return icons[type] || '📄';
    },

    getInspectionTypeName(type) {
      const names = {
        'haupt': 'Hauptprüfung',
        'sonder': 'Sonderprüfung',
        'unter': 'Untersuchung',
        'neben': 'Nebenprüfung'
      };
      return names[type] || type;
    },

    formatDate(dateString) {
      return new Date(dateString).toLocaleDateString('de-DE');
    }
  }
}
</script>

<style scoped>
.pdf-viewer-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  z-index: 1000;
}

.inspection-items {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  max-height: 60vh;
  overflow-y: auto;
  padding: 1rem;
}

.inspection-item {
  background: #f8fafc;
  border-radius: 8px;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.2s;
}

.inspection-item:hover {
  background: #f1f5f9;
  transform: translateY(-2px);
}

.inspection-list {
  padding: 1rem;
}

.loading-state {
  text-align: center;
  padding: 2rem;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #2196F3;
  border-radius: 50%;
  margin: 0 auto 1rem;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.modal-overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.new-inspection-form {
  background: white;
  border-radius: 20px;
  width: 90%;
  max-width: 600px;
  overflow: hidden;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  animation: modalSlideIn 0.3s ease-out;
}

.header-title {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.header-icon {
  font-size: 1.8rem;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e3f2fd;
  border-radius: 10px;
  color: #2196F3;
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group.full-width {
  grid-column: 1 / -1;
}

.label-text {
  color: #4b5563;
  font-weight: 500;
  font-size: 0.95rem;
}

.required {
  color: #dc2626;
  margin-left: 0.25rem;
}

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.input-icon {
  position: absolute;
  left: 1rem;
  color: #6b7280;
  font-size: 1.1rem;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 0.75rem 1rem 0.75rem 2.75rem;
  border: 1px solid #e5e7eb;
  border-radius: 10px;
  font-size: 1rem;
  background: #f9fafb;
  color: #1f2937;
  transition: all 0.2s;
  appearance: none;
}

.form-group select {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%236b7280'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1.25rem;
  padding-right: 2.5rem;
}

.form-group input:focus,
.form-group select:focus {
  outline: none;
  border-color: #2196F3;
  box-shadow: 0 0 0 3px rgba(33, 150, 243, 0.1);
  background: white;
}

.pdf-selection-list {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #e5e7eb;
  border-radius: 12px;
  background: #f9fafb;
}

.pdf-selection-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.2s;
  border-bottom: 1px solid #e5e7eb;
  position: relative;
}

.pdf-selection-item:last-child {
  border-bottom: none;
}

.pdf-selection-item:hover {
  background: #f0f9ff;
}

.pdf-selection-item.selected {
  background: #e3f2fd;
  border-color: #2196F3;
}

.pdf-icon {
  font-size: 1.25rem;
  color: #2196F3;
}

.pdf-name {
  flex: 1;
  color: #4b5563;
  font-size: 0.95rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selected-check {
  width: 24px;
  height: 24px;
  background: #2196F3;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
}

.modal-footer {
  padding: 1.5rem;
  border-top: 1px solid #e5e7eb;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  background: #f8f9fa;
}

.submit-btn,
.cancel-btn {
  padding: 0.75rem 1.5rem;
  border-radius: 10px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-size: 0.95rem;
}

.submit-btn {
  background: #2196F3;
  color: white;
  border: none;
}

.submit-btn:hover:not(:disabled) {
  background: #1976D2;
  transform: translateY(-1px);
}

.submit-btn:disabled {
  background: #94a3b8;
  cursor: not-allowed;
}

.cancel-btn {
  background: #f3f4f6;
  color: #4b5563;
  border: none;
}

.cancel-btn:hover {
  background: #e5e7eb;
  transform: translateY(-1px);
}

.btn-icon {
  font-size: 1.1rem;
}

@media (max-width: 640px) {
  .form-grid {
    grid-template-columns: 1fr;
  }
  
  .modal-footer {
    flex-direction: column-reverse;
  }
  
  .submit-btn,
  .cancel-btn {
    width: 100%;
    justify-content: center;
  }
}

/* Inspection List Styles */
.inspection-list {
  padding: 1.5rem;
}

.inspection-items {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 60vh;
  overflow-y: auto;
  padding-right: 0.5rem;
}

.inspection-item {
  background: #f9fafb;
  border: 1px solid #e5e7eb;
  border-radius: 12px;
  padding: 1.25rem;
  cursor: pointer;
  transition: all 0.2s;
}

.inspection-item:hover {
  background: #f0f9ff;
  border-color: #2196F3;
  transform: translateY(-2px);
}

.inspection-main {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.inspection-icon {
  font-size: 1.5rem;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e3f2fd;
  border-radius: 10px;
  color: #2196F3;
}

.inspection-info {
  flex: 1;
}

.inspection-type {
  display: block;
  color: #1a365d;
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.inspection-meta {
  color: #6b7280;
  font-size: 0.9rem;
}

.empty-state {
  text-align: center;
  padding: 3rem;
  color: #6b7280;
}

.empty-icon {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  display: block;
}

.loading-state {
  text-align: center;
  padding: 3rem;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 3px solid #f3f4f6;
  border-top-color: #2196F3;
  border-radius: 50%;
  margin: 0 auto 1rem;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

@keyframes modalSlideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Custom Scrollbar */
.pdf-selection-list::-webkit-scrollbar,
.inspection-items::-webkit-scrollbar {
  width: 8px;
}

.pdf-selection-list::-webkit-scrollbar-track,
.inspection-items::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.pdf-selection-list::-webkit-scrollbar-thumb,
.inspection-items::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 4px;
}

.pdf-selection-list::-webkit-scrollbar-thumb:hover,
.inspection-items::-webkit-scrollbar-thumb:hover {
  background: #a1a1a1;
}
</style>
