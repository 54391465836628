<template>
  <div class="secure">
    <h1>Secure Page</h1>
    <p>You are logged in!</p>
  </div>
</template>

<script>
export default {
  name: 'Secure'
}
</script>
