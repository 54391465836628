<template>
  <div class="popup-wrapper" @click.stop>
    <div class="popup-overlay" @click.stop="handleClose">
      <div class="popup-content" @click.stop>
        <div class="popup-header">
          <h3>Schaden/Mangel {{ markerId }}</h3>
          <div class="header-buttons">
            <button 
              class="reference-btn asb-btn"
              :class="{ active: showAsbIng }"
              @click="toggleAsbIng"
            >
              <span class="asb-icons">
                <i class="mdi mdi-cog"></i>
                <i class="mdi mdi-hard-hat"></i>
              </span>
              ASB-Ing
            </button>
            <button class="abort-btn" @click="handleClose">×</button>
          </div>
        </div>

        <div class="popup-body">
          <div class="input-group">
            <input 
              type="text" 
              v-model="title" 
              placeholder="Title"
            >
            <div class="photo-controls">
              <button class="camera-btn" @click="takePicture" title="Foto aufnehmen">
                📸
              </button>
              <button 
                class="gallery-btn" 
                @click="toggleGallery" 
                title="Fotos anzeigen"
                v-if="photos.length > 0"
              >
                🖼️ {{ photos.length }}
              </button>
            </div>
          </div>
          <textarea 
            v-model="description" 
            placeholder="Description"
          ></textarea>

          <!-- Add Reference Buttons -->
          <div class="reference-buttons">
            <button 
              class="reference-btn"
              :class="{ active: showHaufigeSchaden }"
              @click="toggleHaufigeSchaden"
            >
              Häufige Schäden
            </button>
          </div>

          <!-- Reference Content -->
          <div v-if="showHaufigeSchaden" class="reference-content">
            <div class="reference-header">
              <h4>Häufige Schäden</h4>
              <button class="close-reference" @click="showHaufigeSchaden = false">×</button>
            </div>
            <div class="reference-body">
              <!-- Add your content for Häufige Schäden here -->
              Hier werden häufige Schäden aufgelistet...
            </div>
          </div>

          <div v-if="showAsbIng" class="reference-content">
            <div class="reference-header">
              <h4>ASB-Ing</h4>
              <button class="close-reference" @click="showAsbIng = false">×</button>
            </div>
            <div class="reference-body">
              <!-- Add your content for ASB-Ing here -->
              ASB-Ing Referenzmaterial...
            </div>
          </div>

          <!-- Measures Section -->
          <div class="measures-section">
            <h4>Maßnahmen</h4>
            <div class="measures-buttons">
              <button 
                v-for="measure in measures" 
                :key="measure.code"
                :class="{ active: selectedMeasure === measure.code }"
                @click="selectMeasure(measure.code)"
              >
                {{ measure.code }}
              </button>
            </div>
            <div v-if="selectedMeasure" class="measure-description">
              {{ getSelectedMeasureDescription }}
            </div>
          </div>

          <!-- Security Risks Section -->
          <div class="security-risks-section">
            <h4>Sicherheitsrisiken</h4>
            <div class="risks-table">
              <!-- Table Header -->
              <div class="risks-header">
                <div class="risk-type-header">Risikoart</div>
                <div class="risk-levels-header">
                  <div v-for="level in riskLevelOptions" :key="level.value" class="level-label">
                    {{ level.value }}
                  </div>
                </div>
              </div>
              
              <!-- Risk Rows -->
              <div v-for="risk in securityRisks" :key="risk.code" class="risk-row">
                <div class="risk-type">
                  <span class="risk-code">{{ risk.code }}</span>
                  <span class="risk-description">{{ risk.description }}</span>
                  <button 
                    class="info-button"
                    @click="toggleRiskInfo(risk.code)"
                  >
                    ⓘ
                  </button>
                  <!-- Add info popup -->
                  <div 
                    v-if="activeRiskInfo === risk.code" 
                    class="risk-info-popup"
                    @click.stop
                  >
                    <div class="info-popup-overlay" @click.stop="closeRiskInfo"></div>
                    <div class="risk-info-content" @click.stop>
                      {{ risk.info }}
                    </div>
                  </div>
                </div>
                <div class="risk-level-buttons">
                  <button
                    v-for="level in riskLevelOptions"
                    :key="level.value"
                    class="risk-level-btn"
                    :class="{ active: riskLevels[risk.code] === level.value }"
                    @click="setRiskLevel(risk.code, level.value)"
                    :title="level.description"
                  >
                    {{ level.value }}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- Add this for the camera input -->
          <input 
            type="file"
            accept="image/*"
            capture="environment"
            ref="cameraInput"
            @change="handleImage"
            style="display: none"
          >

          <!-- Add this to show the captured image -->
          <div v-if="imageData" class="image-preview">
            <img :src="imageData" alt="Captured image">
            <button class="remove-image-btn" @click="removeImage">×</button>
          </div>
        </div>

        <div class="popup-footer">
          <div class="footer-buttons">
            <button 
              v-if="!isNewMarker" 
              class="delete-btn" 
              @click="deleteMarker"
            >
              Löschen
            </button>
            <button class="save-btn" @click="saveData">Speichern</button>
          </div>
        </div>
      </div>
    </div>
    <CameraPopup
      v-if="showCamera"
      :show="showCamera"
      @capture="handleCameraCapture"
      @close="handleCameraClose"
      @error="handleCameraError"
    />
    <div v-if="showGallery" class="gallery-popup">
      <div class="gallery-overlay" @click="closeGallery"></div>
      <div class="gallery-content">
        <div class="gallery-header">
          <h4>Fotos</h4>
          <button class="close-gallery-btn" @click="closeGallery">×</button>
        </div>
        <div class="gallery-grid">
          <div v-for="(photo, index) in photos" :key="index" class="photo-item">
            <img :src="photo" alt="Damage photo">
            <button class="delete-photo-btn" @click="deletePhoto(index)">×</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Update the delete confirmation dialog -->
    <div v-if="showDeleteConfirm" class="delete-confirmation">
      <div class="delete-overlay"></div>
      <div class="delete-dialog">
        <div class="delete-icon">🗑️</div>
        <h3>Marker löschen</h3>
        <p>Möchten Sie diesen Marker wirklich löschen? Diese Aktion kann nicht rückg��ngig gemacht werden.</p>
        <div class="delete-actions">
          <button class="cancel-btn" @click="cancelDelete">Abbrechen</button>
          <button class="confirm-delete-btn" @click="confirmDelete">Löschen</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CameraPopup from './CameraPopup.vue'

export default {
  name: 'PopUpMarker',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    markerId: {
      type: Number,
      required: true
    },
    marker: {
      type: Object,
      required: true
    },
    inspectionId: {
      type: Number,
      required: true
    }
  },
  components: {
    CameraPopup
  },
  data() {
    return {
      title: '',
      description: '',
      selectedMeasure: null,
      riskLevels: {
        SS: '',
        BS: '',
        VS: ''
      },
      measures: [
        { code: '0', description: 'ohne Bedeutung, keine Maßnahme' },
        { code: '1', description: 'Wartung notwendig' },
        { code: '2', description: 'Instandsetzung notwendig' },
        { code: '3', description: 'Teilerneuerung notwendig' },
        { code: '4', description: 'Erneuerung des Bauwerkteiles notwendig' },
        { code: '5', description: 'keine Erhaltung, Inkaufnahme von Betriebseinschränkungen' },
        { code: '6', description: 'Verzicht auf Bauwerk' },
        { code: 'S', description: 'Sonstige Maßnahmen' }
      ],
      riskLevelOptions: [
        { value: '0', description: 'Keine Gefährdung' },
        { value: '1', description: 'Beginnende/Mögliche Gefährdung - Beobachtung erforderlich' },
        { value: '2', description: 'Mittelfristige Gefährdung - Maßnahmen einplanen' },
        { value: '3', description: 'Unmittelbare Gefährdung - Sofortmaßnahmen erforderlich' }
      ],
      securityRisks: [
        { code: 'SS', name: 'Standsicherheit' },
        { code: 'VS', name: 'Verkehrssicherheit' },
        { code: 'BS', name: 'Bausubstanz' }
      ],
      activeRiskInfo: null,
      showHaufigeSchaden: false,
      showAsbIng: false,
      imageData: null,
      showCamera: false,
      showGallery: false,
      photos: [],
      showDeleteConfirm: false
    }
  },
  computed: {
    getSelectedMeasureDescription() {
      const measure = this.measures.find(m => m.code === this.selectedMeasure)
      return measure ? measure.description : ''
    },
    isNewMarker() {
      const savedMarkers = JSON.parse(localStorage.getItem('markers') || '[]')
      return !savedMarkers.some(m => m.id === this.markerId)
    }
  },
  methods: {
    handleClose() {
      // Reset cursor and emit close event
      document.body.style.cursor = 'default';
      this.$emit('abort');  // Emit abort event to trigger cleanup
      this.$emit('close');  // Emit close event to close popup
    },
    selectMeasure(code) {
      this.selectedMeasure = this.selectedMeasure === code ? null : code
    },
    async saveData() {
      try {
        const markerData = {
          inspection_id: this.inspectionId,
          number: this.marker.markerNumber,
          x: this.marker.relativeX,
          y: this.marker.relativeY,
          page: this.marker.page,
          scale: this.marker.scale,
          damage_level: this.marker.damage_level,
          title: this.title,
          description: this.description,
          measure: this.selectedMeasure || '',
          risks: {
            SS: this.riskLevels.SS || '0',
            BS: this.riskLevels.BS || '0',
            VS: this.riskLevels.VS || '0'
          },
          images: [],
          pdf_file: null
        };

        // Log the data being sent
        console.log('Saving marker data:', markerData);

        const response = await fetch(`${process.env.VUE_APP_API_URL}/FastAPI/inspections/${markerData.inspection_id}/markers`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(markerData)
        });

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
        }

        const result = await response.json();
        console.log('Marker saved successfully:', result);
        
        // If we have photos to upload, do it after marker is created
        if (this.photos.length > 0) {
          for (const photo of this.photos) {
            // Convert base64 to blob
            const response = await fetch(photo);
            const blob = await response.blob();
            
            // Create form data
            const formData = new FormData();
            formData.append('file', blob, 'image.jpg');
            
            // Upload image
            const imageResponse = await fetch(`${process.env.VUE_APP_API_URL}/FastAPI/markers/${result.id}/images`, {
              method: 'POST',
              body: formData
            });
            
            if (!imageResponse.ok) {
              console.error('Failed to upload image:', await imageResponse.text());
            }
          }
        }
        
        this.$emit('save', result);
        this.$emit('close');
      } catch (error) {
        console.error('Error saving marker:', error);
      }
    },
    
    closePopup() {
      this.$emit('close');
    },
    calculateGrade() {
      const values = Object.values(this.riskLevels)
        .map(v => parseInt(v || '0'))
        .filter(v => !isNaN(v));
      
      if (values.length === 0) return 0;
      return (values.reduce((a, b) => a + b, 0) / values.length).toFixed(1);
    },
    resetForm() {
      console.log('Resetting form') // Debug log
      this.title = ''
      this.description = ''
      this.selectedMeasure = null
      this.riskLevels = {
        SS: '',
        BS: '',
        VS: ''
      }
      this.photos = []
      this.imageData = null
      this.showHaufigeSchaden = false
      this.showAsbIng = false
      this.activeRiskInfo = null
    },
    setRiskLevel(riskCode, value) {
      this.riskLevels[riskCode] = this.riskLevels[riskCode] === value ? '' : value
    },
    toggleRiskInfo(riskCode) {
      this.activeRiskInfo = this.activeRiskInfo === riskCode ? null : riskCode
    },
    closeRiskInfo() {
      this.activeRiskInfo = null
    },
    abortMarker() {
      this.$emit('abort')
      this.handleClose()
    },
    toggleHaufigeSchaden() {
      this.showHaufigeSchaden = !this.showHaufigeSchaden
      this.showAsbIng = false // Close other panel if open
    },
    toggleAsbIng() {
      this.showAsbIng = !this.showAsbIng
      this.showHaufigeSchaden = false // Close other panel if open
    },
    // Update loadMarkerData to properly load existing data
    loadMarkerData() {
      const savedMarkers = JSON.parse(localStorage.getItem('markers') || '[]')
      const existingMarker = savedMarkers.find(m => m.id === this.markerId)
      
      if (existingMarker) {
        // Load existing data
        this.title = existingMarker.title || ''
        this.description = existingMarker.description || ''
        this.selectedMeasure = existingMarker.measure || null
        this.riskLevels = existingMarker.risks || existingMarker.defaultRiskLevels || { SS: '', BS: '', VS: '' }
        this.photos = existingMarker.photos || []
      } else {
        // For new markers, check if we have default risk levels
        const defaultRiskLevels = existingMarker && existingMarker.defaultRiskLevels
        if (defaultRiskLevels) {
          this.riskLevels = { ...defaultRiskLevels }
        } else {
          this.resetForm()
        }
      }
    },
    takePicture() {
      this.showCamera = true
    },
    handleCameraCapture(imageData) {
      this.photos.push(imageData)
      this.showCamera = false
    },
    handleCameraClose() {
      this.showCamera = false
    },
    handleCameraError() {
      this.showCamera = false
      // Fallback to file input
      this.$refs.cameraInput.click()
    },
    handleImage(event) {
      const file = event.target.files[0]
      if (file) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.imageData = e.target.result
        }
        reader.readAsDataURL(file)
      }
    },
    removeImage() {
      this.imageData = null
      this.$refs.cameraInput.value = ''
    },
    toggleGallery() {
      this.showGallery = !this.showGallery
    },
    closeGallery() {
      this.showGallery = false
    },
    deletePhoto(index) {
      this.photos.splice(index, 1)
      if (this.photos.length === 0) {
        this.closeGallery()
      }
    },
    deleteMarker() {
      // Simply show the confirmation dialog
      this.showDeleteConfirm = true
    },
    cancelDelete() {
      // Simply hide the confirmation dialog
      this.showDeleteConfirm = false
    },
    confirmDelete() {
      // Emit delete event once and close everything
      this.$emit('delete', this.markerId)
      this.showDeleteConfirm = false
      this.handleClose()
    }
  },
  // Add created hook to load data when component is created
  created() {
    // Initialize with default risk levels if provided
    if (this.defaultRiskLevels && Object.values(this.defaultRiskLevels).every(v => v !== '')) {
      this.riskLevels = { ...this.defaultRiskLevels }
      
      // Calculate initial grade
      const values = Object.values(this.riskLevels).map(v => parseInt(v))
      const grade = (values.reduce((a, b) => a + b, 0) / values.length).toFixed(1)
      
      // Set damage level based on grade
      if (grade <= 1.0) this.damageLevel = 'S0'
      else if (grade <= 1.9) this.damageLevel = 'S1'
      else if (grade <= 2.5) this.damageLevel = 'S2'
      else if (grade <= 2.9) this.damageLevel = 'S3'
      else this.damageLevel = 'S4'
    } else {
      this.resetForm()
    }
  },
  mounted() {
    console.log('PopUpMarker mounted:', { marker: this.marker, markerId: this.markerId });
    if (this.marker) {
      this.title = this.marker.title || '';
      this.description = this.marker.description || '';
      this.selectedMeasure = this.marker.measure || null;
      this.riskLevels = this.marker.risks || { SS: '', BS: '', VS: '' };
      this.photos = this.marker.photos || [];
    } else {
      this.resetForm();
    }
  },
  watch: {
    riskLevels: {
      deep: true,
      handler(newLevels) {
        // Only if all risk levels are set
        if (Object.values(newLevels).every(v => v !== '')) {
          const values = Object.values(newLevels).map(v => parseInt(v))
          const average = values.reduce((a, b) => a + b, 0) / values.length
          
          // Determine and emit new damage level
          let damageLevel
          if (average <= 1.0) damageLevel = 'S0'
          else if (average <= 1.9) damageLevel = 'S1'
          else if (average <= 2.5) damageLevel = 'S2'
          else if (average <= 2.9) damageLevel = 'S3'
          else damageLevel = 'S4'

          this.$emit('damage-level-change', damageLevel)
        }
      }
    },
    // Add watcher for marker prop to load data when marker changes
    marker: {
      immediate: true,
      handler(newMarker) {
        if (newMarker) {
          this.title = newMarker.title || '';
          this.description = newMarker.description || '';
          this.selectedMeasure = newMarker.measure || null;
          this.riskLevels = newMarker.risks || { SS: '', BS: '', VS: '' };
          this.photos = newMarker.photos || [];
        }
      }
    }
  }
}
</script>

<style scoped>
.popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
}

.popup-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-shrink: 0;
}

.close-btn {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.popup-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  padding-right: 10px;
  flex: 1;
}

input, textarea {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

textarea {
  min-height: 100px;
  resize: vertical;
}

.popup-footer {
  margin-top: 20px;
  text-align: right;
  flex-shrink: 0;
}

button {
  padding: 8px 16px;
  background: #2196F3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background: #1976D2;
}

.measures-section, .security-risks-section {
  margin-top: 20px;
}

h4 {
  margin-bottom: 10px;
  color: #666;
}

.measures-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 10px;
}

.measures-buttons button {
  padding: 6px 12px;
  border: 1px solid #ddd;
  background: white;
  color: #666;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.measures-buttons button.active {
  background: #2196F3;
  color: white;
  border-color: #2196F3;
}

.risks-table {
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
}

.risks-header {
  display: flex;
  background: #f5f5f5;
  border-bottom: 1px solid #ddd;
  padding: 8px;
}

.risk-type-header {
  flex: 1;
  font-weight: bold;
  color: #666;
}

.risk-levels-header {
  display: flex;
  gap: 4px;
  width: 160px;
  justify-content: space-around;
}

.level-label {
  width: 30px;
  text-align: center;
  font-weight: bold;
  color: #666;
}

.risk-row {
  display: flex;
  padding: 8px;
  border-bottom: 1px solid #ddd;
  align-items: center;
}

.risk-row:last-child {
  border-bottom: none;
}

.risk-type {
  flex: 1;
  display: flex;
  gap: 8px;
  align-items: center;
  position: relative;
}

.risk-code {
  font-weight: bold;
  color: #333;
  min-width: 30px;
}

.risk-description {
  color: #666;
  font-size: 0.9em;
}

.risk-level-buttons {
  display: flex;
  gap: 4px;
  width: 160px;
  justify-content: space-around;
}

.risk-level-btn {
  width: 30px;
  height: 30px;
  padding: 0;
  border: 1px solid #ddd;
  background: white;
  color: #666;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.risk-level-btn.active {
  background: #2196F3;
  color: white;
  border-color: #2196F3;
}

.risk-level-btn:hover {
  background: #E3F2FD;
}

.risk-level-btn.active:hover {
  background: #1976D2;
}

.measure-description {
  margin-top: 10px;
  padding: 10px;
  background: #f5f5f5;
  border-radius: 4px;
  font-size: 14px;
  color: #666;
  line-height: 1.4;
}

.info-button {
  padding: 2px 8px;
  background: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  color: #666;
  font-size: 12px;
  transition: all 0.2s ease;
}

.info-button:hover {
  background: #e0e0e0;
}

.risk-info-popup {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;
}

.info-popup-overlay {
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.3);
}

.risk-info-content {
  position: relative;
  background: white;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.2);
  font-size: 13px;
  line-height: 1.4;
  color: #666;
  white-space: pre-line;
  max-height: 80vh;
  overflow-y: auto;
  width: 300px;
  max-width: 90vw;
  z-index: 1;
}

.risk-info-popup::before {
  display: none;
}

.close-info-btn {
  display: none;
}

.header-buttons {
  display: flex;
  gap: 12px;
  align-items: center;
}

.icon-btn {
  background: none;
  border: none;
  padding: 4px;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.icon-btn:hover {
  background: #f0f0f0;
}

.asb-btn {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  font-size: 12px;
  background: #f0f0f0;
  color: #666;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.asb-btn:before {
  content: '🔧'; /* Tool emoji for now, until we get the proper icons working */
  font-size: 14px;
}

.asb-btn:hover {
  background: #e0e0e0;
}

.asb-btn.active {
  background: #2196F3;
  color: white;
  border-color: #2196F3;
}

.abort-btn {
  width: 28px;
  height: 28px;
  font-size: 18px;
  background: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  transition: background-color 0.2s;
}

.abort-btn:hover {
  background: #c82333;
}

.reference-buttons {
  display: flex;
  gap: 10px;
  margin: 10px 0;
}

.reference-btn {
  flex: 1;
  padding: 8px 16px;
  background: #f0f0f0;
  color: #666;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.reference-btn:hover {
  background: #e0e0e0;
}

.reference-btn.active {
  background: #2196F3;
  color: white;
  border-color: #2196F3;
}

.reference-content {
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #f9f9f9;
}

.reference-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: #f0f0f0;
  border-bottom: 1px solid #ddd;
}

.reference-header h4 {
  margin: 0;
  color: #333;
}

.close-reference {
  background: none;
  border: none;
  font-size: 20px;
  color: #666;
  cursor: pointer;
  padding: 0 5px;
}

.reference-body {
  padding: 15px;
  max-height: 200px;
  overflow-y: auto;
}

.input-group {
  display: flex;
  gap: 8px;
  align-items: center;
}

.photo-controls {
  display: flex;
  gap: 8px;
}

.camera-btn {
  padding: 8px;
  background: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.camera-btn:hover {
  background: #e0e0e0;
}

.image-preview {
  position: relative;
  margin: 10px 0;
  max-width: 100%;
  border-radius: 4px;
  overflow: hidden;
}

.image-preview img {
  width: 100%;
  height: auto;
  display: block;
}

.remove-image-btn {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  background: rgba(220, 53, 69, 0.9);
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 0;
}

.remove-image-btn:hover {
  background: rgba(200, 35, 51, 1);
}

.camera-preview {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.camera-preview video {
  max-width: 100%;
  max-height: 100%;
}

.capture-btn {
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: white;
  border: none;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 8px rgba(0,0,0,0.2);
}

.camera-close-btn {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(255,255,255,0.8);
  border: none;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
}

.popup-body::-webkit-scrollbar {
  width: 8px;
}

.popup-body::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.popup-body::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.popup-body::-webkit-scrollbar-thumb:hover {
  background: #666;
}

.gallery-popup {
  position: fixed;
  inset: 0;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery-overlay {
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
}

.gallery-content {
  position: relative;
  background: white;
  border-radius: 8px;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

.gallery-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #ddd;
}

.gallery-header h4 {
  margin: 0;
}

.close-gallery-btn {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 4px;
}

.gallery-grid {
  padding: 16px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  overflow-y: auto;
  max-height: calc(90vh - 70px);
}

.photo-item {
  position: relative;
  aspect-ratio: 1;
  border-radius: 4px;
  overflow: hidden;
}

.photo-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.delete-photo-btn {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: rgba(220, 53, 69, 0.9);
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 0;
}

.delete-photo-btn:hover {
  background: rgba(200, 35, 51, 1);
}

.gallery-btn {
  padding: 8px;
  background: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 4px;
  transition: all 0.2s ease;
}

.gallery-btn:hover {
  background: #e0e0e0;
}

.footer-buttons {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.delete-btn {
  background: #dc3545;
}

.delete-btn:hover {
  background: #c82333;
}

.save-btn {
  background: #2196F3;
}

.save-btn:hover {
  background: #1976D2;
}

.delete-confirmation {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
}

.delete-overlay {
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
}

.delete-dialog {
  position: relative;
  background: white;
  padding: 24px;
  border-radius: 12px;
  width: 90%;
  max-width: 400px;
  text-align: center;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  animation: slideUp 0.3s ease-out;
}

.delete-icon {
  font-size: 48px;
  margin-bottom: 16px;
}

.delete-dialog h3 {
  margin: 0 0 12px 0;
  color: #333;
  font-size: 20px;
}

.delete-dialog p {
  margin: 0 0 24px 0;
  color: #666;
  line-height: 1.5;
}

.delete-actions {
  display: flex;
  gap: 12px;
  justify-content: center;
}

.cancel-btn {
  padding: 10px 20px;
  background: #f0f0f0;
  color: #666;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
}

.cancel-btn:hover {
  background: #e0e0e0;
}

.confirm-delete-btn {
  padding: 10px 20px;
  background: #dc3545;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
}

.confirm-delete-btn:hover {
  background: #c82333;
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
