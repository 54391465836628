<template>
  <div class="project-dashboard">
    <!-- Project Header -->
    <div class="project-header">
      <div class="project-info">
        <div class="project-title">
          <span class="project-icon">📋</span>
          <h2>{{ project.name }}</h2>
        </div>
        <div class="project-details">
          <span class="project-number" v-if="project.number">
            #{{ project.number }}
          </span>
          <span class="project-date">
            {{ formatDate(project.date) }}
          </span>
          <span class="project-description" v-if="project.description">
            {{ project.description }}
          </span>
        </div>
      </div>
    </div>

    <!-- PDF List Header -->
    <div class="pdf-list-header">
      <div class="pdf-header-top">
        <h3>Verfügbare PDFs</h3>
        <div class="pdf-stats">
          <span class="pdf-count">{{ project.pdfs ? project.pdfs.length : 0 }} PDFs</span>
        </div>
      </div>

      <!-- PDF List -->
      <div class="pdf-list" v-if="project.pdfs && project.pdfs.length > 0">
        <div 
          v-for="pdf in project.pdfs" 
          :key="pdf.id"
          class="pdf-item"
          :class="{ active: selectedPdfId === pdf.id }"
          @click="selectPdf(pdf)"
        >
          <div class="pdf-main">
            <span class="pdf-icon">📄</span>
            <div class="pdf-info">
              <span class="pdf-name">{{ pdf.filename }}</span>
              <div class="pdf-meta">
                <span class="pdf-date">Hochgeladen: {{ formatDate(pdf.uploaded_at) }}</span>
                <span class="pdf-size" v-if="pdf.size">{{ formatFileSize(pdf.size) }}</span>
              </div>
            </div>
          </div>
          
          <div class="pdf-actions">
            <button class="pdf-action" @click.stop="downloadPdf(pdf)" title="Herunterladen">
              <span class="action-icon">️</span>
            </button>
            <button class="pdf-action delete" @click.stop="deletePdf(pdf)" title="Löschen">
              <span class="action-icon">🗑️</span>
            </button>
          </div>
        </div>
      </div>

      <!-- Empty State -->
      <div v-else class="pdf-empty-state">
        <span class="empty-icon">📁</span>
        <p>Keine PDFs vorhanden</p>
        <p class="empty-hint">Klicken Sie auf "Import PDF" um PDFs hochzuladen</p>
      </div>
    </div>

    <!-- Action Buttons -->
    <div class="action-buttons">
      <!-- Upload new PDF button -->
      <label class="action-card">
        <input 
          type="file" 
          @change="onFileChange" 
          accept="application/pdf"
          style="display: none"
        >
        <span class="card-icon">📄</span>
        <span class="card-title">Neues PDF</span>
      </label>

      <!-- New Inspection button - opens selection modal -->
      <button class="action-card" @click="showNewInspectionModal = true">
        <span class="card-icon">🔍</span>
        <span class="card-title">Neue Prüfung</span>
      </button>

      <button class="action-card" @click="loadLastInspection">
        <span class="card-icon">📋</span>
        <span class="card-title">Letzte Prüfung</span>
      </button>

      <button class="action-card" @click="loadInspection">
        <span class="card-icon">📋</span>
        <span class="card-title">Lade Prüfung</span>
      </button>

      <button class="action-card db-card" @click="startDBMode">
        <img src="https://upload.wikimedia.org/wikipedia/commons/d/d5/Deutsche_Bahn_AG-Logo.svg" alt="DB Logo" class="db-logo">
        <span class="card-title">DB Prüfung</span>
      </button>

      <button class="action-card delete-card" @click="deleteInspection">
        <span class="card-icon">🗑️</span>
        <span class="card-title">Lösche Prüfung</span>
      </button>
    </div>

    <!-- New Inspection Modal -->
    <div v-if="showNewInspectionModal" class="modal-overlay" @click="showNewInspectionModal = false">
      <div class="modal-content new-inspection-form" @click.stop>
        <div class="modal-header">
          <div class="header-title">
            <span class="header-icon">🔍</span>
            <h3>Neue Prüfung erstellen</h3>
          </div>
          <button class="close-btn" @click="showNewInspectionModal = false">×</button>
        </div>

        <div class="modal-body">
          <div class="form-grid">
            <!-- Date Selection -->
            <div class="form-group">
              <label>
                <span class="label-text">Prüfungsdatum</span>
                <span class="required">*</span>
              </label>
              <div class="input-wrapper">
                <span class="input-icon">📅</span>
                <input 
                  v-model="newInspection.date" 
                  type="date" 
                  required
                >
              </div>
            </div>

            <!-- Inspection Type -->
            <div class="form-group">
              <label>
                <span class="label-text">Art der Prüfung</span>
                <span class="required">*</span>
              </label>
              <div class="input-wrapper">
                <span class="input-icon">📋</span>
                <select v-model="newInspection.type" required>
                  <option value="haupt">Hauptprüfung</option>
                  <option value="sonder">Sonderprüfung</option>
                  <option value="unter">Untersuchung</option>
                  <option value="neben">Nebenprüfung</option>
                </select>
              </div>
            </div>

            <!-- PDF Selection -->
            <div class="form-group full-width">
              <label>
                <span class="label-text">PDF auswählen</span>
                <span class="required">*</span>
              </label>
              <div class="pdf-selection-list">
                <div 
                  v-for="pdf in project.pdfs" 
                  :key="pdf.id"
                  class="pdf-selection-item"
                  :class="{ selected: newInspection.pdfId === pdf.id }"
                  @click="newInspection.pdfId = pdf.id"
                >
                  <span class="pdf-icon">📄</span>
                  <span class="pdf-name">{{ pdf.filename }}</span>
                  <span v-if="newInspection.pdfId === pdf.id" class="selected-check">✓</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button class="cancel-btn" @click="showNewInspectionModal = false">
            <span class="btn-icon">✕</span>
            <span>Abbrechen</span>
          </button>
          <button 
            class="submit-btn" 
            @click="createNewInspection" 
            :disabled="!isInspectionFormValid"
          >
            <span class="btn-icon">✓</span>
            <span>Prüfung erstellen</span>
          </button>
        </div>
      </div>
    </div>

    <!-- Add HandlerInspection component -->
    <HandlerInspection
      v-if="project.id"
      :project-id="project.id"
      :project-pdfs="project.pdfs || []"
      ref="handlerInspection"
    />
  </div>
</template>

<script>
import HandlerInspection from './HandlerInspection.vue'

export default {
  name: 'ProjektDashboard',
  
  components: {
    HandlerInspection
  },
  
  data() {
    return {
      selectedPdfId: null,
      isLoading: false,
      showNewInspectionModal: false,
      newInspection: {
        date: new Date().toISOString().split('T')[0],
        type: 'haupt',
        pdfId: null,
        inspections: []
      }
    }
  },

  props: {
    project: {
      type: Object,
      required: true
    }
  },

  // Add created hook to fetch PDFs when component mounts
  async created() {
    await this.fetchProjectPdfs();
    await this.fetchInspections();
  },

  // Add watch for project changes
  watch: {
    'project.id': {
      handler: 'fetchProjectPdfs',
      immediate: true
    }
  },

  computed: {
    isInspectionFormValid() {
      return this.newInspection.date && 
             this.newInspection.type && 
             this.newInspection.pdfId;
    }
  },

  methods: {
    // Add method to fetch PDFs
    async fetchProjectPdfs() {
      if (!this.project.id) return;
      
      try {
        this.isLoading = true;
        const response = await fetch(
          `${process.env.VUE_APP_API_URL}/FastAPI/projects/${this.project.id}/pdfs`
        );
        
        if (!response.ok) throw new Error('Failed to fetch PDFs');
        
        const pdfs = await response.json();
        console.log('Fetched PDFs:', pdfs); // Debug log
        
        // Update parent component
        this.$emit('pdfs-loaded', pdfs);
        
      } catch (error) {
        console.error('Error fetching PDFs:', error);
      } finally {
        this.isLoading = false;
      }
    },

    // Update existing methods
    async onFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        try {
          const formData = new FormData();
          formData.append('file', file);

          const response = await fetch(
            `${process.env.VUE_APP_API_URL}/FastAPI/projects/${this.project.id}/pdfs`,
            {
              method: 'POST',
              body: formData
            }
          );

          if (!response.ok) throw new Error('Upload failed');

          const pdfData = await response.json();
          console.log('PDF uploaded successfully:', pdfData.filename);

          // Refresh PDFs after upload
          await this.fetchProjectPdfs();
          
          // Clear the input
          event.target.value = '';
          
        } catch (error) {
          console.error('Error uploading PDF:', error);
          alert('Fehler beim Hochladen der PDF');
        }
      }
    },

    async deletePdf(pdf) {
      if (confirm(`Möchten Sie "${pdf.filename}" wirklich löschen?`)) {
        try {
          const response = await fetch(
            `${process.env.VUE_APP_API_URL}/FastAPI/projects/${this.project.id}/pdfs/${pdf.id}`,
            { method: 'DELETE' }
          );
          
          if (!response.ok) throw new Error('Delete failed');
          
          // Refresh PDFs after deletion
          await this.fetchProjectPdfs();
          
        } catch (error) {
          console.error('Error deleting PDF:', error);
          alert('Fehler beim Löschen der PDF');
        }
      }
    },

    formatDate(dateString) {
      return new Date(dateString).toLocaleDateString('de-DE');
    },

    loadLastInspection() {
      this.$emit('load-last-inspection');
    },

    loadInspection() {
      // Get reference to HandlerInspection component
      const handler = this.$refs.handlerInspection;
      if (handler) {
        handler.showInspectionList = true;
        handler.fetchInspections();
      }
    },

    deleteInspection() {
      this.$emit('delete-inspection');
    },

    selectPdf(pdf) {
      this.selectedPdfId = pdf.id;
      this.$emit('pdf-selected', pdf);
    },

    async downloadPdf(pdf) {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_URL}/FastAPI/projects/${this.project.id}/pdfs/${pdf.id}/download`
        );
        
        if (!response.ok) throw new Error('Download failed');
        
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = pdf.filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      } catch (error) {
        console.error('Error downloading PDF:', error);
        alert('Fehler beim Herunterladen der PDF');
      }
    },

    async createNewInspection() {
      try {
        if (!this.newInspection.pdfId) {
          alert('Bitte wählen Sie eine PDF aus');
          return;
        }

        // Convert reactive object to plain data
        const inspectionData = {
          date: this.newInspection.date,
          type: this.newInspection.type,
          pdfId: Number(this.newInspection.pdfId)  // Ensure pdfId is a number
        };

        console.log('Sending inspection data:', inspectionData);

        const response = await fetch(
          `${process.env.VUE_APP_API_URL}/FastAPI/projects/${this.project.id}/inspections`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(inspectionData)
          }
        );

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.detail || 'Failed to create inspection');
        }

        const data = await response.json();
        console.log('Received response:', data);
        
        // Close modal
        this.showNewInspectionModal = false;
        
        // Emit event to parent
        this.$emit('start-inspection', {
          inspection: data.inspection,
          pdf: data.pdf
        });

      } catch (error) {
        console.error('Error creating inspection:', error);
        alert('Fehler beim Erstellen der Prüfung: ' + error.message);
      }
    },

    // Add new methods
    async fetchInspections() {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_URL}/FastAPI/projects/${this.project.id}/inspections`
        );
        
        if (!response.ok) throw new Error('Failed to fetch inspections');
        
        this.inspections = await response.json();
      } catch (error) {
        console.error('Error fetching inspections:', error);
      }
    },

    getInspectionIcon(type) {
      const icons = {
        'haupt': '🔍',
        'sonder': '🔎',
        'unter': '📋',
        'neben': '📝'
      };
      return icons[type] || '📄';
    },

    getInspectionTypeName(type) {
      const names = {
        'haupt': 'Hauptprüfung',
        'sonder': 'Sonderprüfung',
        'unter': 'Untersuchung',
        'neben': 'Nebenprüfung'
      };
      return names[type] || type;
    },

    startDBMode() {
      // This will be implemented later when we create the DB-specific component
      console.log('Starting DB Mode - To be implemented');
    }
  }
}
</script>

<style scoped>
.project-dashboard {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.project-header {
  background: white;
  padding: 1rem 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  margin-bottom: 2rem;
}

.project-info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.project-title {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.project-icon {
  font-size: 1.5rem;
}

.project-title h2 {
  margin: 0;
  font-size: 1.5rem;
  color: #1e293b;
}

.project-details {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  color: #64748b;
  font-size: 0.95rem;
}

.project-number {
  font-family: monospace;
  background: #f1f5f9;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
}

.action-buttons {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  padding: 1rem;
}

.action-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  border: none;
  transition: all 0.2s;
}

.action-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card-icon {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.card-title {
  color: #1e293b;
  font-weight: 500;
}

.delete-card {
  color: #ef4444;
}

.pdf-list-header {
  background: white;
  border-radius: 8px;
  padding: 1.5rem;
  margin: 1rem 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.pdf-list-header h3 {
  margin: 0 0 1rem 0;
  color: #1e293b;
  font-size: 1.2rem;
}

.pdf-list {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.pdf-item {
  display: flex;
  align-items: center;
  padding: 0.75rem;
  background: #f8fafc;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s;
}

.pdf-item:hover {
  background: #f1f5f9;
}

.pdf-item.active {
  background: #e0f2fe;
  border: 1px solid #2196F3;
}

.pdf-icon {
  font-size: 1.5rem;
  margin-right: 1rem;
}

.pdf-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.pdf-name {
  color: #1e293b;
  font-weight: 500;
}

.pdf-date {
  color: #64748b;
  font-size: 0.9rem;
}

.pdf-action {
  background: none;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.pdf-action:hover {
  opacity: 1;
}

.action-icon {
  font-size: 1.2rem;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  max-width: 400px;
  width: 100%;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.close-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
}

.modal-body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.form-group label {
  color: #1e293b;
  font-weight: 500;
}

.form-group input,
.form-group select {
  padding: 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 4px;
}

.pdf-selection-list {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.pdf-selection-item {
  display: flex;
  align-items: center;
  padding: 0.75rem;
  background: #f8fafc;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s;
}

.pdf-selection-item:hover {
  background: #f1f5f9;
}

.pdf-selection-item.selected {
  background: #e0f2fe;
  border: 1px solid #2196F3;
}

.empty-state {
  text-align: center;
  padding: 2rem;
  color: #64748b;
}

.empty-icon {
  font-size: 2rem;
  margin-bottom: 1rem;
  display: block;
}

.empty-hint {
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

/* New Inspection Form Styles */
.new-inspection-form {
  max-width: 600px;
  width: 90%;
}

.header-title {
  display: flex;
  align-items: center;
  gap: 12px;
}

.header-icon {
  font-size: 24px;
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-group.full-width {
  grid-column: 1 / -1;
}

.label-text {
  font-weight: 500;
  color: #374151;
}

.required {
  color: #DC2626;
  margin-left: 4px;
}

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.input-icon {
  position: absolute;
  left: 12px;
  color: #6B7280;
  font-size: 16px;
}

input[type="date"],
select {
  width: 100%;
  padding: 8px 12px 8px 36px;
  border: 1px solid #D1D5DB;
  border-radius: 6px;
  font-size: 14px;
  transition: all 0.2s;
}

input[type="date"]:focus,
select:focus {
  outline: none;
  border-color: #3B82F6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

.pdf-selection-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 200px;
  overflow-y: auto;
  padding: 8px;
  border: 1px solid #D1D5DB;
  border-radius: 6px;
}

.pdf-selection-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
}

.pdf-selection-item:hover {
  background: #F3F4F6;
}

.pdf-selection-item.selected {
  background: #EFF6FF;
  border: 1px solid #3B82F6;
}

.pdf-icon {
  font-size: 20px;
}

.pdf-name {
  flex: 1;
  font-size: 14px;
}

.selected-check {
  color: #3B82F6;
  font-weight: bold;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  padding: 20px;
  border-top: 1px solid #E5E7EB;
}

.cancel-btn,
.submit-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s;
}

.cancel-btn {
  background: #F3F4F6;
  color: #4B5563;
}

.cancel-btn:hover {
  background: #E5E7EB;
}

.submit-btn {
  background: #3B82F6;
  color: white;
}

.submit-btn:hover:not(:disabled) {
  background: #2563EB;
}

.submit-btn:disabled {
  background: #93C5FD;
  cursor: not-allowed;
}

.btn-icon {
  font-size: 16px;
}

.db-card {
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
}

.db-card .db-logo {
  width: 48px;
  height: 48px;
  margin-bottom: 1.5rem;
  transition: transform 0.3s ease;
  display: block;
}

.db-card:hover .db-logo {
  transform: scale(1.1);
}

.db-card::before {
  background: linear-gradient(90deg, #F01414, #EC0016);
}
</style> 