# Create a new component for viewing marker details
<template>
  <div class="view-marker-popup" @click.stop>
    <div class="popup-overlay" @click.stop="handleClose">
      <div class="popup-content" @click.stop>
        <div class="popup-header">
          <h3>Schaden/Mangel {{ marker.number }}</h3>
          <button class="close-btn" @click="handleClose">×</button>
        </div>

        <div class="popup-body">
          <div class="info-section">
            <h4>Details</h4>
            <div class="info-row">
              <span class="label">Titel:</span>
              <span class="value">{{ marker.title }}</span>
            </div>
            <div class="info-row">
              <span class="label">Beschreibung:</span>
              <span class="value">{{ marker.description }}</span>
            </div>
            <div class="info-row">
              <span class="label">Maßnahme:</span>
              <span class="value">{{ marker.measure }}</span>
            </div>
            <div class="info-row">
              <span class="label">Schadensstufe:</span>
              <span class="value" :class="'damage-level-' + marker.damage_level.toLowerCase()">
                {{ marker.damage_level }}
              </span>
            </div>
          </div>

          <div class="risks-section">
            <h4>Sicherheitsrisiken</h4>
            <div class="risks-grid">
              <div class="risk-item" v-for="(level, type) in marker.risks" :key="type">
                <span class="risk-type">{{ type }}</span>
                <span class="risk-level" :class="'risk-' + level">{{ level }}</span>
              </div>
            </div>
          </div>

          <div class="images-section" v-if="marker.images && marker.images.length > 0">
            <h4>Bilder</h4>
            <div class="image-grid">
              <div v-for="(image, index) in marker.images" :key="index" class="image-item">
                <img :src="image" :alt="'Damage photo ' + (index + 1)">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ViewMarker',
  props: {
    marker: {
      type: Object,
      required: true
    }
  },
  methods: {
    handleClose() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>
.view-marker-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

.popup-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background: white;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.popup-header {
  padding: 20px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-btn {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
}

.popup-body {
  padding: 20px;
}

.info-section, .risks-section, .images-section {
  margin-bottom: 24px;
}

h4 {
  margin-bottom: 12px;
  color: #333;
}

.info-row {
  margin-bottom: 8px;
}

.label {
  font-weight: 500;
  color: #666;
  margin-right: 8px;
}

.risks-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 12px;
}

.risk-item {
  padding: 8px;
  border: 1px solid #eee;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.risk-type {
  font-weight: 500;
  margin-bottom: 4px;
}

.risk-level {
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.9em;
}

.risk-0 { background: #E8F5E9; color: #2E7D32; }
.risk-1 { background: #E3F2FD; color: #1565C0; }
.risk-2 { background: #FFF3E0; color: #EF6C00; }
.risk-3 { background: #FFEBEE; color: #C62828; }

.damage-level-s0 { color: #2E7D32; }
.damage-level-s1 { color: #1565C0; }
.damage-level-s2 { color: #F9A825; }
.damage-level-s3 { color: #EF6C00; }
.damage-level-s4 { color: #C62828; }

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 12px;
}

.image-item img {
  width: 100%;
  height: 120px;
  object-fit: cover;
  border-radius: 4px;
}
</style>
