<template>
  <div class="pdf-viewer-container">
    <!-- Toolbar -->
    <div class="pdf-toolbar">
      <div class="toolbar-section">
        <button @click="$emit('close')" class="toolbar-btn">
          ← Zurück
        </button>
        <span>{{ inspectionData.type }} - {{ formatInspectionDate }}</span>
      </div>
      
      <!-- Add Zoom Controls -->
      <div class="toolbar-section">
        <button @click="zoomOut" class="toolbar-btn" title="Verkleinern">
          🔍-
        </button>
        <button @click="resetZoom" class="toolbar-btn" title="Zoom zurücksetzen">
          100%
        </button>
        <button @click="zoomIn" class="toolbar-btn" title="Vergrößern">
          🔍+
        </button>
      </div>

      <!-- Damage Level Selection -->
      <div class="toolbar-section damage-levels">
        <button 
          v-for="level in damageLevels"
          :key="level.code"
          :class="['toolbar-btn', `damage-${level.code.toLowerCase()}`, { active: selectedDamageLevel === level.code }]"
          @click="selectDamageLevel(level.code)"
        >
          {{ level.code }}
        </button>
      </div>
    </div>

    <!-- PDF Display -->
    <div class="pdf-container">
      <div id="pdf-viewer" @click="handlePdfClick">
        <!-- PDF canvas will be rendered here -->
      </div>

      <!-- Marker Popups -->
      <PopUpMarker
        v-if="showPopUpMarker && selectedMarker && isAddingMarker"
        :marker="selectedMarker"
        :markerId="selectedMarker.id"
        :inspectionId="inspectionData.id"
        @save="saveMarkerDetails"
        @abort="closeMarkerPopup"
      />

      <ViewMarker
        v-if="showMarkerPopup && selectedMarker && !isAddingMarker"
        :show="showMarkerPopup"
        :marker="selectedMarker"
        @close="showMarkerPopup = false"
      />
    </div>

    <!-- Legend -->
    <div class="pdf-legend">
      <div class="legend-section">
        <h4>Schadensklassen</h4>
        <div class="legend-items">
          <div 
            v-for="level in damageLevels"
            :key="level.code"
            class="legend-item"
          >
            <div 
              class="legend-symbol"
              :style="{ backgroundColor: level.color }"
            >
              {{ level.code }}
            </div>
            <span class="legend-text">{{ level.description }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Import PDF.js
import * as pdfjsLib from 'pdfjs-dist';
import PopUpMarker from './PopUpMarker.vue'
import ViewMarker from './ViewMarker.vue'

// Set worker path
pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

export default {
  name: 'PdfViewer',
  
  props: {
    pdfUrl: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      pdfDoc: null,
      pageNum: 1,
      scale: 1.0,
      isAddingMarker: false,
      showMarkerPopup: false,
      showPopUpMarker: false,
      selectedMarker: null,
      selectedDamageLevel: null,
      markers: [],
      markerClickAreas: [],
      inspectionData: null,
      damageLevels: [
        { code: 'S0', description: 'Kein Schaden', color: '#4CAF50' },
        { code: 'S1', description: 'Geringer Schaden', color: '#FFC107' },
        { code: 'S2', description: 'Mittlerer Schaden', color: '#FF9800' },
        { code: 'S3', description: 'Schwerer Schaden', color: '#F44336' },
        { code: 'S4', description: 'Sehr schwerer Schaden', color: '#9C27B0' }
      ]
    }
  },

  computed: {
    formatInspectionDate() {
      if (this.inspectionData && this.inspectionData.date) {
        return new Date(this.inspectionData.date).toLocaleDateString('de-DE');
      }
      return '';
    },

    markerId() {
      return this.selectedMarker ? this.selectedMarker.id : null;
    }
  },

  methods: {
    async loadInspectionData() {
      const savedInspection = localStorage.getItem('currentInspection');
      if (savedInspection) {
        this.inspectionData = JSON.parse(savedInspection);
        // Load markers immediately after inspection data is loaded
        await this.loadMarkers();
      }
    },

    saveMarkers() {
      if (this.inspectionData && this.inspectionData.id) {
        // Format markers data to match backend expectations
        const formattedMarkers = this.markers.map(marker => ({
          inspection_id: this.inspectionData.id,
          x: marker.relativeX || marker.x,
          y: marker.relativeY || marker.y,
          page: marker.page,
          scale: marker.scale,
          number: marker.markerNumber || marker.number,
          damage_level: marker.damage_level,
          title: marker.title || '',
          description: marker.description || '',
          measure: marker.measure || '',
          risks: marker.risks || {},
          images: marker.images || []
        }));

        // Save markers to backend using POST
        fetch(
          `${process.env.VUE_APP_API_URL}/FastAPI/inspections/${this.inspectionData.id}/markers`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(formattedMarkers)
          }
        ).catch(error => {
          console.error('Error saving markers:', error);
        });

        // Update local storage
        const savedInspection = JSON.parse(localStorage.getItem('currentInspection'));
        savedInspection.markers = this.markers;
        localStorage.setItem('currentInspection', JSON.stringify(savedInspection));
      }
    },

    getMarkerStyle(marker) {
      const damageLevel = this.damageLevels.find(level => level.code === marker.damage_level);
      const style = {
        left: `${marker.x}%`,
        top: `${marker.y}%`,
        backgroundColor: damageLevel ? damageLevel.color : '#999'
      };
      
      // Get canvas for logging
      const pdfViewer = document.getElementById('pdf-viewer');
      const canvas = pdfViewer && pdfViewer.querySelector('canvas');
      if (canvas) {
        console.log('Marker position:', {
          markerId: marker.id,
          markerNumber: marker.number,
          x: marker.x,
          y: marker.y,
          leftPx: (marker.x / 100) * canvas.width,
          topPx: (marker.y / 100) * canvas.height,
          style: style
        });
      }
      
      return style;
    },

    async loadPdf() {
      try {
        const response = await fetch(this.pdfUrl);
        if (!response.ok) throw new Error('Failed to load PDF');
        
        const blob = await response.blob();
        this.pdfDoc = await pdfjsLib.getDocument(URL.createObjectURL(blob)).promise;
        await this.renderPage(this.pageNum);

        // After PDF is loaded and rendered, check for existing markers
        if (this.inspectionData && this.inspectionData.id) {
          await this.loadMarkers();
        }
      } catch (error) {
        console.error('Error loading PDF:', error);
      }
    },

    async renderPage(num) {
      if (!this.pdfDoc) return;
      
      try {
        const page = await this.pdfDoc.getPage(num);
        const viewport = page.getViewport({ scale: this.scale });
        
        // Get PDF container
        const pdfContainer = document.getElementById('pdf-viewer');
        if (!pdfContainer) return;

        // Get existing canvas or create new one
        let canvas = pdfContainer.querySelector('canvas');
        if (!canvas) {
          canvas = document.createElement('canvas');
          pdfContainer.appendChild(canvas);
        }

        // Set canvas dimensions
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        
        // Get context and clear it
        const context = canvas.getContext('2d');
        context.clearRect(0, 0, canvas.width, canvas.height);

        // Render PDF page
        await page.render({
          canvasContext: context,
          viewport: viewport
        }).promise;

        // After PDF is rendered, render markers if they exist
        if (this.markers && this.markers.length > 0) {
          this.renderMarkers();
        }

      } catch (error) {
        console.error('Error rendering page:', error);
      }
    },

    drawMarkers(canvas) {
      if (!canvas || !this.markers) return;

      const ctx = canvas.getContext('2d');
      
      // Clear existing click areas
      this.markerClickAreas = [];

      this.markers.forEach(marker => {
        if (marker.page === this.pageNum) {
          // Calculate marker position in pixels
          const x = marker.x * canvas.width;
          const y = marker.y * canvas.height;

          // Find damage level color
          const damageLevel = this.damageLevels.find(level => level.code === marker.damage_level);
          const color = damageLevel ? damageLevel.color : '#FF0000';

          // Draw marker circle
          ctx.beginPath();
          ctx.arc(x, y, 10, 0, 2 * Math.PI);
          ctx.fillStyle = color;
          ctx.fill();
          
          // Add white border
          ctx.strokeStyle = 'white';
          ctx.lineWidth = 3;
          ctx.stroke();

          // Add black outline
          ctx.strokeStyle = 'black';
          ctx.lineWidth = 1;
          ctx.stroke();

          // Add marker number
          ctx.fillStyle = 'white';
          ctx.font = 'bold 12px Arial';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillText(marker.number.toString(), x, y);

          // Add click area
          this.markerClickAreas.push({
            x: x - 15,
            y: y - 15,
            width: 30,
            height: 30,
            marker: marker
          });
        }
      });

      // Add click handler if not already added
      if (!canvas.onclick) {
        canvas.onclick = (event) => {
          const rect = canvas.getBoundingClientRect();
          const pdfContainer = document.getElementById('pdf-viewer');
          const x = event.clientX - rect.left + pdfContainer.scrollLeft;
          const y = event.clientY - rect.top + pdfContainer.scrollTop;

          // Check for marker clicks
          const clickedMarker = this.markerClickAreas.find(area => 
            x >= area.x && x <= area.x + area.width &&
            y >= area.y && y <= area.y + area.height
          );

          if (clickedMarker) {
            this.handleMarkerClick(clickedMarker.marker);
            event.stopPropagation();
            return;
          }

          // Handle new marker creation
          if (this.isAddingMarker && this.selectedDamageLevel) {
            this.handlePdfClick(event);
          }
        };
      }
    },

    // Zoom controls
    zoomIn() {
      this.scale += 0.25;
      this.renderPage(this.pageNum);
    },

    zoomOut() {
      if (this.scale > 0.5) {
        this.scale -= 0.25;
        this.renderPage(this.pageNum);
      }
    },

    resetZoom() {
      this.scale = 1.0;
      this.renderPage(this.pageNum);
    },

    // Marker handling
    toggleMarkerMode() {
      this.isAddingMarker = !this.isAddingMarker;
      document.body.style.cursor = this.isAddingMarker ? 'crosshair' : 'default';
    },

    handlePdfClick(event) {
      if (!this.isAddingMarker || !this.selectedDamageLevel) return;

      const canvas = document.getElementById('pdf-viewer').querySelector('canvas');
      if (!canvas) return;

      const rect = canvas.getBoundingClientRect();
      const pdfContainer = document.getElementById('pdf-viewer');
      const containerRect = pdfContainer.getBoundingClientRect();

      // Get scroll position of the container
      const scrollX = pdfContainer.scrollLeft;
      const scrollY = pdfContainer.scrollTop;

      // Calculate click position relative to canvas, accounting for container scroll
      const x = (event.clientX - rect.left + scrollX);
      const y = (event.clientY - containerRect.top + scrollY);

      // Store relative coordinates (as percentages)
      const relativeX = x / rect.width;
      const relativeY = y / rect.height;

      // Create new marker with relative coordinates
      const newMarker = {
        id: Date.now(),
        relativeX: relativeX,
        relativeY: relativeY,
        page: this.pageNum,
        scale: this.scale,
        damage_level: this.selectedDamageLevel,
        markerNumber: this.markers.length + 1
      };

      this.markers.push(newMarker);
      this.renderMarkers();

      // Show popup for the new marker
      this.selectedMarker = newMarker;
      this.showPopUpMarker = true;

      // Reset marker mode
      this.isAddingMarker = true;
      document.body.style.cursor = 'default';
    },

    openMarker(marker) {
      this.isAddingMarker = false;
      document.body.style.cursor = 'default';
      
      this.selectedDamageLevel = marker.damage_level;
      this.selectedMarker = { ...marker };
      this.showMarkerPopup = true;
    },

    saveMarker(markerData) {
      const isNewMarker = !this.markers.find(m => m.id === markerData.id);
      
      if (isNewMarker) {
        this.markers.push({
          ...this.selectedMarker,
          ...markerData
        });
      } else {
        const index = this.markers.findIndex(m => m.id === markerData.id);
        if (index !== -1) {
          this.markers[index] = {
            ...this.markers[index],
            ...markerData
          };
        }
      }

      // Save to localStorage
      const savedInspection = JSON.parse(localStorage.getItem('currentInspection'));
      if (savedInspection) {
        savedInspection.markers = this.markers;
        localStorage.setItem('currentInspection', JSON.stringify(savedInspection));
      }

      this.selectedMarker = null;
      this.showMarkerPopup = false;
      this.isAddingMarker = false;
      document.body.style.cursor = 'default';
    },

    deleteMarker(markerId) {
      this.markers = this.markers.filter(m => m.id !== markerId);
      
      // Format remaining markers
      const formattedMarkers = this.markers.map(marker => ({
        inspection_id: this.inspectionData.id,
        x: marker.relativeX || marker.x,
        y: marker.relativeY || marker.y,
        page: marker.page,
        scale: marker.scale,
        number: marker.markerNumber || marker.number,
        damage_level: marker.damage_level,
        title: marker.title || '',
        description: marker.description || '',
        measure: marker.measure || '',
        risks: marker.risks || {},
        images: marker.images || []
      }));

      // Update backend using POST
      fetch(
        `${process.env.VUE_APP_API_URL}/FastAPI/inspections/${this.inspectionData.id}/markers`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formattedMarkers)
        }
      );

      // Update localStorage
      const savedInspection = JSON.parse(localStorage.getItem('currentInspection'));
      savedInspection.markers = this.markers;
      localStorage.setItem('currentInspection', JSON.stringify(savedInspection));

      this.selectedMarker = null;
      this.showMarkerPopup = false;
    },

    selectDamageLevel(level) {
      this.selectedDamageLevel = level;
      this.isAddingMarker = true;
      document.body.style.cursor = 'crosshair';
    },

    // Add method to reset damage level selection
    resetDamageLevel() {
      this.selectedDamageLevel = null;
      this.isAddingMarker = false;
      document.body.style.cursor = 'default';
    },

    closeMarkerPopup() {
      // If canceling a new marker, remove it
      if (this.selectedMarker && !this.selectedMarker.description) {
        const index = this.markers.findIndex(m => m.id === this.selectedMarker.id);
        if (index !== -1) {
          this.markers.splice(index, 1);
          this.renderMarkers();
        }
      }
      this.showMarkerPopup = false;
      this.showPopUpMarker = false;
      this.selectedMarker = null;
      this.selectedDamageLevel = null;
      this.isAddingMarker = false;
      document.body.style.cursor = 'default';
    },

    async saveMarkerDetails(markerData) {
      if (this.selectedMarker) {
        try {
          // Prepare marker data
          const data = {
            inspection_id: this.inspectionData.id,
            x: this.selectedMarker.relativeX,
            y: this.selectedMarker.relativeY,
            page: this.selectedMarker.page,
            scale: this.selectedMarker.scale,
            number: this.selectedMarker.markerNumber,
            damage_level: this.selectedMarker.damage_level,
            title: markerData.title,
            description: markerData.description,
            measure: markerData.measure,
            risks: markerData.risks,
            images: markerData.images || []
          };

          // Send to backend
          const response = await fetch(
            `${process.env.VUE_APP_API_URL}/FastAPI/inspections/${this.inspectionData.id}/markers`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(data)
            }
          );

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const result = await response.json();
          console.log('Marker saved:', result);

          // Update the marker in our local array with the returned data
          const index = this.markers.findIndex(m => m.id === this.selectedMarker.id);
          if (index !== -1) {
            this.markers[index] = {
              ...result,
              relativeX: result.x,
              relativeY: result.y,
              markerNumber: result.number
            };
          } else {
            this.markers.push({
              ...result,
              relativeX: result.x,
              relativeY: result.y,
              markerNumber: result.number
            });
          }

          // Update local storage
          const savedInspection = JSON.parse(localStorage.getItem('currentInspection'));
          if (savedInspection) {
            savedInspection.markers = this.markers;
            localStorage.setItem('currentInspection', JSON.stringify(savedInspection));
          }

          // Reset UI state
          this.showMarkerPopup = false;
          this.showPopUpMarker = false;
          this.selectedMarker = null;
          this.resetDamageLevel(); // Reset damage level selection

          // Re-render markers
          this.renderMarkers();
        } catch (error) {
          console.error('Error saving marker:', error);
        }
      }
    },

    // Add method to render markers
    renderMarkers() {
      const canvas = document.getElementById('pdf-viewer').querySelector('canvas');
      if (!canvas) return;

      // Get the canvas context and save its state
      const ctx = canvas.getContext('2d');
      ctx.save();

      // Clear existing click areas but don't clear the canvas
      this.markerClickAreas = [];

      this.markers.forEach(marker => {
        if (marker.page === this.pageNum) {
          // Calculate marker position
          const x = marker.x * canvas.width;
          const y = marker.y * canvas.height;

          // Find damage level color
          const damageLevel = this.damageLevels.find(level => level.code === marker.damage_level);
          const color = damageLevel ? damageLevel.color : '#FF0000';

          // Draw marker
          ctx.beginPath();
          ctx.arc(x, y, 10, 0, 2 * Math.PI);
          ctx.fillStyle = color;
          ctx.fill();
          
          // Add white border
          ctx.strokeStyle = 'white';
          ctx.lineWidth = 3;
          ctx.stroke();

          // Add black outline
          ctx.strokeStyle = 'black';
          ctx.lineWidth = 1;
          ctx.stroke();

          // Add click area
          const clickArea = {
            x: x - 15,
            y: y - 15,
            width: 30,
            height: 30,
            marker: marker
          };
          this.markerClickAreas.push(clickArea);
        }
      });

      // Restore canvas context
      ctx.restore();

      // Add click handler to canvas
      canvas.onclick = (event) => {
        const rect = canvas.getBoundingClientRect();
        const pdfContainer = document.getElementById('pdf-viewer');
        const x = event.clientX - rect.left + pdfContainer.scrollLeft;
        const y = event.clientY - rect.top + pdfContainer.scrollTop;

        // Check for marker clicks
        const clickedMarker = this.markerClickAreas.find(area => 
          x >= area.x && x <= area.x + area.width &&
          y >= area.y && y <= area.y + area.height
        );

        if (clickedMarker) {
          this.handleMarkerClick(clickedMarker.marker);
          event.stopPropagation();
          return;
        }

        // Handle new marker creation
        if (this.isAddingMarker && this.selectedDamageLevel) {
          this.handlePdfClick(event);
        }
      };
    },

    async loadMarkers() {
      try {
        console.log('Loading markers for inspection:', this.inspectionData.id);
        const response = await fetch(
          `${process.env.VUE_APP_API_URL}/FastAPI/inspections/${this.inspectionData.id}/markers`
        );

        if (!response.ok) {
          throw new Error(`Failed to load markers: ${response.status}`);
        }

        const loadedMarkers = await response.json();
        console.log('Loaded markers from backend:', loadedMarkers);

        if (loadedMarkers && loadedMarkers.length > 0) {
          this.markers = loadedMarkers.map(marker => ({
            id: marker.id,
            x: marker.x,
            y: marker.y,
            page: marker.page || 1,
            scale: marker.scale,
            number: marker.number,
            damage_level: marker.damage_level,
            title: marker.title || '',
            description: marker.description || '',
            measure: marker.measure || '',
            risks: marker.risks || {},
            images: marker.images || []
          }));

          // Render the markers on the PDF
          this.renderMarkers();
        }
      } catch (error) {
        console.error('Error loading markers:', error);
      }
    },

    // Add method to handle marker clicks
    handleMarkerClick(marker) {
      // Don't show popup if we're in adding mode
      if (this.isAddingMarker) return;
      
      this.selectedMarker = marker;
      this.showMarkerPopup = true;
      this.showPopUpMarker = false; // Ensure we don't show the edit popup
    },
  },

  created() {
    this.loadInspectionData();
  },

  mounted() {
    if (this.pdfUrl) {
      this.loadPdf();
    }
  },

  watch: {
    pdfUrl(newUrl) {
      if (newUrl) {
        this.loadPdf();
      }
    },
    pageNum() {
      this.renderPage(this.pageNum);
    },
    scale() {
      this.renderPage(this.pageNum);
    }
  },

  components: {
    PopUpMarker,
    ViewMarker
  }
}
</script>

<style scoped>
.pdf-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background: white;
  border-bottom: 1px solid #ddd;
  gap: 1rem;
}

.toolbar-section {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.toolbar-btn {
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  transition: all 0.2s;
  min-width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toolbar-btn:hover {
  background: #f0f0f0;
}

.toolbar-btn.active {
  background: #e3f2fd;
  border-color: #2196F3;
  color: #2196F3;
}

.damage-levels {
  display: flex;
  gap: 0.25rem;
}

/* Add damage level colors */
.damage-s0 { color: #4CAF50; }
.damage-s1 { color: #FFC107; }
.damage-s2 { color: #FF9800; }
.damage-s3 { color: #F44336; }
.damage-s4 { color: #9C27B0; }

.toolbar-btn.active.damage-s0 { background: #E8F5E9; }
.toolbar-btn.active.damage-s1 { background: #FFF8E1; }
.toolbar-btn.active.damage-s2 { background: #FFF3E0; }
.toolbar-btn.active.damage-s3 { background: #FFEBEE; }
.toolbar-btn.active.damage-s4 { background: #F3E5F5; }

.pdf-container {
  position: relative;
  width: 100%;
  height: calc(100vh - 120px);
  overflow: auto;
  background: #f0f0f0;
}

#pdf-viewer {
  position: relative;
  margin: 0 auto;
  background: white;
}

.marker {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #2196F3;
  transform: translate(-50%, -50%);
  cursor: pointer;
  border: 2px solid white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  transition: all 0.2s ease;
}

.marker:hover {
  transform: translate(-50%, -50%) scale(1.2);
  box-shadow: 0 4px 8px rgba(0,0,0,0.3);
}

/* Damage level colors */
.marker.damage-level-s0 { background: #4CAF50; }  /* Green - Minor */
.marker.damage-level-s1 { background: #2196F3; }  /* Blue - Low */
.marker.damage-level-s2 { background: #FFC107; }  /* Yellow - Medium */
.marker.damage-level-s3 { background: #FF9800; }  /* Orange - High */
.marker.damage-level-s4 { background: #F44336; }  /* Red - Critical */

.legend-section {
  padding: 1rem;
  background: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0.5rem 0;
}

.legend-symbol {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 0.8rem;
}

#pdf-viewer canvas {
  display: block;
  margin: 0 auto;
}

/* Update damage level button styles */
.toolbar-btn.damage-s0 { 
  background-color: #4CAF50; 
  color: white;
  border-color: #4CAF50;
}
.toolbar-btn.damage-s1 { 
  background-color: #FFC107; 
  color: black;
  border-color: #FFC107;
}
.toolbar-btn.damage-s2 { 
  background-color: #FF9800; 
  color: white;
  border-color: #FF9800;
}
.toolbar-btn.damage-s3 { 
  background-color: #F44336; 
  color: white;
  border-color: #F44336;
}
.toolbar-btn.damage-s4 { 
  background-color: #9C27B0; 
  color: white;
  border-color: #9C27B0;
}

/* Update active states */
.toolbar-btn.active.damage-s0 { 
  box-shadow: 0 0 0 2px #4CAF50; 
}
.toolbar-btn.active.damage-s1 { 
  box-shadow: 0 0 0 2px #FFC107; 
}
.toolbar-btn.active.damage-s2 { 
  box-shadow: 0 0 0 2px #FF9800; 
}
.toolbar-btn.active.damage-s3 { 
  box-shadow: 0 0 0 2px #F44336; 
}
.toolbar-btn.active.damage-s4 { 
  box-shadow: 0 0 0 2px #9C27B0; 
}

/* Legend styles */
.pdf-legend {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.legend-section {
  background: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.15);
  min-width: 250px;
}

.legend-section h4 {
  margin: 0 0 10px 0;
  color: #333;
  font-size: 14px;
}

.legend-items {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.legend-symbol {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 12px;
  font-weight: bold;
}

.legend-text {
  color: #666;
  font-size: 13px;
}

/* Marker styles matching the buttons */
.marker.damage-s0 { background-color: #4CAF50; }
.marker.damage-s1 { background-color: #FFC107; color: black; }
.marker.damage-s2 { background-color: #FF9800; }
.marker.damage-s3 { background-color: #F44336; }
.marker.damage-s4 { background-color: #9C27B0; }
</style> 