<template>
  <div class="inspection">
    <!-- Landing Page View -->
    <div class="landing-page">
      <h1>QUAGG Inspection</h1>

      <!-- Project Creation/Selection Phase -->
      <div v-if="!currentProject">
        <div class="action-buttons">
          <button class="action-card" @click="showNewProjectForm = true">
            <span class="card-icon">📝</span>
            <span class="card-title">Neues Projekt</span>
          </button>

          <button class="action-card" @click="showProjectList = true">
            <span class="card-icon">📂</span>
            <span class="card-title">Bestehendes Projekt</span>
          </button>
        </div>

        <!-- Project Form Modal -->
        <ProjektForm 
          v-if="showNewProjectForm || showProjectList"
          :mode="showNewProjectForm ? 'create' : 'load'"
          @close="closeForm"
          @project-selected="onProjectSelected"
        />
      </div>

      <!-- Project Dashboard -->
      <ProjektDashboard
        v-else
        :project="currentProject"
        @pdfs-loaded="updateProjectPdfs"
      />
    </div>
  </div>
</template>

<script>
import ProjektForm from '@/components/ProjektForm.vue'
import ProjektDashboard from '@/components/ProjektDashboard.vue'

export default {
  name: 'Inspection',
  
  components: {
    ProjektForm,
    ProjektDashboard
  },

  data() {
    return {
      showNewProjectForm: false,
      showProjectList: false,
      currentProject: null
    }
  },

  methods: {
    closeForm() {
      this.showNewProjectForm = false;
      this.showProjectList = false;
    },

    onProjectSelected(project) {
      this.currentProject = project;
      this.closeForm();
    },

    updateProjectPdfs(pdfs) {
      if (this.currentProject) {
        this.currentProject = {
          ...this.currentProject,
          pdfs: pdfs
        };
      }
    }
  }
}
</script>

<style scoped>
.inspection {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(135deg, #f5f7fa 0%, #e4e8eb 100%);
}

.landing-page {
  width: 100%;
  max-width: 1200px;
  padding: 4rem 2rem;
  text-align: center;
}

.landing-page h1 {
  font-size: 2.5rem;
  color: #1a365d;
  margin-bottom: 2rem;
  font-weight: 600;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.action-buttons {
  display: flex;
  justify-content: center;
  gap: 3rem;
  margin-top: 3rem;
  padding: 1rem;
}

.action-card {
  width: 320px;
  padding: 2.5rem 2rem;
  background: white;
  border: none;
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05),
              0 10px 15px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.action-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(90deg, #2196F3, #64B5F6);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.action-card:hover {
  transform: translateY(-6px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.08),
              0 15px 25px rgba(0, 0, 0, 0.12);
}

.action-card:hover::before {
  opacity: 1;
}

.card-icon {
  font-size: 3rem;
  margin-bottom: 1.5rem;
  display: block;
  transition: transform 0.3s ease;
}

.action-card:hover .card-icon {
  transform: scale(1.1);
}

.card-title {
  color: #1e293b;
  font-size: 1.25rem;
  font-weight: 600;
  margin-top: 1rem;
  transition: color 0.3s ease;
}

.action-card:hover .card-title {
  color: #2196F3;
}

/* Responsive Design */
@media (max-width: 768px) {
  .action-buttons {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  .action-card {
    width: 100%;
    max-width: 320px;
  }

  .landing-page h1 {
    font-size: 2rem;
  }
}

/* Animation for cards */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.action-card {
  animation: fadeInUp 0.5s ease-out forwards;
}

.action-card:nth-child(2) {
  animation-delay: 0.2s;
}
</style>
