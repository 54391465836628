<template>
  <div class="camera-popup" v-if="show">
    <div class="camera-overlay">
      <div class="camera-content">
        <video ref="video" autoplay playsinline></video>
        <div class="camera-controls">
          <button class="capture-btn" @click="captureImage">📸</button>
          <button class="close-btn" @click="closeCamera">×</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CameraPopup',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      stream: null
    }
  },
  methods: {
    async startCamera() {
      try {
        this.stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: 'environment' }
        })
        this.$refs.video.srcObject = this.stream
      } catch (err) {
        console.error('Camera error:', err)
        this.$emit('error')
      }
    },
    captureImage() {
      const video = this.$refs.video
      const canvas = document.createElement('canvas')
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight
      
      const context = canvas.getContext('2d')
      context.drawImage(video, 0, 0)
      
      const imageData = canvas.toDataURL('image/jpeg')
      this.$emit('capture', imageData)
      this.closeCamera()
    },
    closeCamera() {
      if (this.stream) {
        this.stream.getTracks().forEach(track => track.stop())
      }
      this.$emit('close')
    }
  },
  watch: {
    show: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.$nextTick(() => {
            this.startCamera()
          })
        }
      }
    }
  },
  beforeDestroy() {
    this.closeCamera()
  }
}
</script>

<style scoped>
.camera-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
}

.camera-overlay {
  width: 100%;
  height: 100%;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.camera-content {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

video {
  max-width: 100%;
  max-height: 100%;
}

.camera-controls {
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.capture-btn {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: white;
  border: none;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 8px rgba(0,0,0,0.2);
}

.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(255,255,255,0.8);
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style> 