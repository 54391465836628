<template>
  <div class="modal-overlay" @click="$emit('close')">
    <div v-if="mode === 'create'" class="modal-content project-form" @click.stop>
      <div class="modal-header">
        <div class="header-title">
          <span class="header-icon">📋</span>
          <h3>Neues Projekt erstellen</h3>
        </div>
        <button class="close-btn" @click="$emit('close')">×</button>
      </div>

      <div class="form-container">
        <div class="form-section">
          <div class="form-group">
            <label>Projektname <span class="required">*</span></label>
            <input 
              v-model="formData.name" 
              type="text" 
              placeholder="z.B. Brückeninspektion A7"
              required
            >
          </div>

          <div class="form-group">
            <label>Beschreibung</label>
            <textarea 
              v-model="formData.description"
              placeholder="Beschreiben Sie das Projekt kurz..."
              rows="4"
            ></textarea>
          </div>

          <div class="form-row">
            <div class="form-group half">
              <label>Datum <span class="required">*</span></label>
              <input 
                v-model="formData.date" 
                type="date" 
                required
              >
            </div>
            
            <div class="form-group half">
              <label>Projektnummer</label>
              <input 
                v-model="formData.number" 
                type="text" 
                placeholder="Optional"
              >
            </div>
          </div>

          <div class="form-group">
            <label>PDF Dateien</label>
            <div class="file-upload">
              <input 
                type="file"
                @change="handlePdfSelect"
                accept="application/pdf"
                ref="pdfInput"
                multiple
                style="display: none"
              >
              <div 
                class="file-upload-box"
                :class="{ 'has-files': selectedPdfs.length > 0 }"
                @click="$refs.pdfInput.click()"
                @dragover.prevent
                @drop.prevent="handlePdfDrop"
              >
                <div v-if="selectedPdfs.length === 0">
                  <span class="upload-icon">📄</span>
                  <span class="upload-text">PDF Dateien auswählen oder hierher ziehen</span>
                </div>
                <div v-else class="selected-files">
                  <div v-for="(pdf, index) in selectedPdfs" :key="index" class="file-item">
                    <span class="file-icon">📄</span>
                    <span class="file-name">{{ pdf.name }}</span>
                    <button class="remove-file" @click.stop="removePdf(index)">×</button>
                  </div>
                </div>
                <div v-if="isUploading" class="upload-progress">
                  <div class="progress-bar">
                    <div 
                      class="progress-fill" 
                      :style="{ width: `${uploadProgress}%` }"
                    ></div>
                  </div>
                  <span class="progress-text">{{ uploadProgress }}%</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-footer">
        <button class="cancel-btn" @click="$emit('close')">
          <span class="btn-icon">✕</span>
          Abbrechen
        </button>
        <button class="submit-btn" @click="submitForm">
          <span class="btn-icon">✓</span>
          Projekt erstellen
        </button>
      </div>
    </div>

    <div v-else class="modal-content project-list" @click.stop>
      <div class="modal-header">
        <h3>Projekt laden</h3>
        <button class="close-btn" @click="$emit('close')">×</button>
      </div>

      <div class="project-list-content">
        <div v-if="isLoading" class="loading-state">
          <div class="spinner"></div>
          <p>Lade Projekte...</p>
        </div>
        
        <div v-else-if="projects.length === 0" class="empty-state">
          <p>Keine Projekte gefunden</p>
        </div>
        
        <div v-else class="project-items">
          <div 
            v-for="project in projects" 
            :key="project.id"
            class="project-item"
            @click="selectProject(project)"
          >
            <div class="project-info">
              <h4>{{ project.name }}</h4>
              <div class="project-meta">
                <span class="project-date">{{ formatDate(project.date) }}</span>
                <span v-if="project.number" class="project-number">#{{ project.number }}</span>
              </div>
              <p v-if="project.description" class="project-description">
                {{ project.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjektForm',

  props: {
    mode: {
      type: String,
      default: 'create',
      validator: value => ['create', 'load'].includes(value)
    }
  },

  data() {
    return {
      formData: {
        name: '',
        description: '',
        date: new Date().toISOString().split('T')[0],
        number: ''
      },
      selectedPdfs: [],
      uploadProgress: 0,
      isUploading: false,
      isLoading: false,
      currentUploadFile: null,
      projects: []
    }
  },

  watch: {
    mode: {
      immediate: true,
      handler(newMode) {
        if (newMode === 'load') {
          this.loadProjects();
        }
      }
    }
  },

  methods: {
    handlePdfSelect(event) {
      const files = Array.from(event.target.files);
      files.forEach(file => {
        if (file.type === 'application/pdf') {
          this.selectedPdfs.push(file);
        } else {
          alert('Bitte nur PDF Dateien auswählen');
        }
      });
    },

    handlePdfDrop(event) {
      const files = Array.from(event.dataTransfer.files);
      files.forEach(file => {
        if (file.type === 'application/pdf') {
          this.selectedPdfs.push(file);
        } else {
          alert('Bitte nur PDF Dateien auswählen');
        }
      });
    },

    removePdf(index) {
      this.selectedPdfs.splice(index, 1);
    },

    async submitForm() {
      try {
        this.isLoading = true;
        
        // Validate required fields
        if (!this.formData.name.trim()) {
          alert('Bitte geben Sie einen Projektnamen ein.');
          return;
        }
        if (!this.formData.date) {
          alert('Bitte wählen Sie ein Datum aus.');
          return;
        }

        // First create the project
        const projectResponse = await fetch(`${process.env.VUE_APP_API_URL}/FastAPI/projects`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            name: this.formData.name.trim(),
            description: this.formData.description ? this.formData.description.trim() : '',
            date: this.formData.date,
            number: this.formData.number ? this.formData.number.trim() : ''
          })
        });

        if (!projectResponse.ok) {
          throw new Error('Failed to create project');
        }

        const projectData = await projectResponse.json();
        console.log('Project created:', projectData);

        // Upload PDFs if any
        const uploadedPdfs = [];
        if (this.selectedPdfs.length > 0) {
          for (const pdf of this.selectedPdfs) {
            this.currentUploadFile = pdf.name;
            this.uploadProgress = 0;
            
            const formData = new FormData();
            formData.append('file', pdf);

            const pdfResponse = await fetch(
              `${process.env.VUE_APP_API_URL}/FastAPI/projects/${projectData.id}/pdfs`,
              {
                method: 'POST',
                body: formData
              }
            );

            if (pdfResponse.ok) {
              const pdfData = await pdfResponse.json();
              uploadedPdfs.push(pdfData);
              this.uploadProgress = 100;
            }
          }
        }

        // Add PDFs to project data
        projectData.pdfs = uploadedPdfs;

        // Emit success with complete project data
        this.$emit('project-created', projectData);
        
        // Reset form and close
        this.resetForm();
        this.$emit('close');

      } catch (error) {
        console.error('Error in form submission:', error);
        alert('Fehler beim Erstellen des Projekts: ' + error.message);
      } finally {
        this.isLoading = false;
        this.currentUploadFile = null;
        this.uploadProgress = 0;
      }
    },

    resetForm() {
      this.formData = {
        name: '',
        description: '',
        date: new Date().toISOString().split('T')[0],
        number: ''
      };
      this.selectedPdfs = [];
      if (this.$refs.pdfInput) {
        this.$refs.pdfInput.value = '';
      }
    },

    async loadProjects() {
      this.isLoading = true;
      try {
        const response = await fetch(`${process.env.VUE_APP_API_URL}/FastAPI/projects`);
        if (!response.ok) throw new Error('Failed to load projects');
        
        this.projects = await response.json();
      } catch (error) {
        console.error('Error loading projects:', error);
        alert('Fehler beim Laden der Projekte');
      } finally {
        this.isLoading = false;
      }
    },

    selectProject(project) {
      this.$emit('project-selected', project);
    },

    formatDate(dateString) {
      return new Date(dateString).toLocaleDateString('de-DE');
    }
  }
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  border-radius: 20px;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  animation: modalSlideIn 0.3s ease-out;
}

.modal-header {
  padding: 1.5rem;
  border-bottom: 1px solid #e5e7eb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to right, #f8f9fa, #ffffff);
}

.header-title {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.header-icon {
  font-size: 1.8rem;
}

.modal-header h3 {
  margin: 0;
  color: #1a365d;
  font-size: 1.5rem;
  font-weight: 600;
}

.close-btn {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: none;
  background: #f3f4f6;
  color: #4b5563;
  font-size: 1.2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
}

.close-btn:hover {
  background: #e5e7eb;
  color: #1f2937;
}

.form-container {
  padding: 2rem;
}

.form-section {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  color: #4b5563;
  font-weight: 500;
  font-size: 0.95rem;
}

.required {
  color: #dc2626;
  margin-left: 0.25rem;
}

input, textarea {
  padding: 0.75rem 1rem;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.2s;
  background: #f9fafb;
}

input:focus, textarea:focus {
  outline: none;
  border-color: #2196F3;
  box-shadow: 0 0 0 3px rgba(33, 150, 243, 0.1);
  background: white;
}

.form-row {
  display: flex;
  gap: 1rem;
}

.form-group.half {
  flex: 1;
}

.file-upload-box {
  border: 2px dashed #e5e7eb;
  border-radius: 12px;
  padding: 2rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s;
  background: #f9fafb;
}

.file-upload-box:hover {
  border-color: #2196F3;
  background: #f0f9ff;
}

.upload-icon {
  font-size: 2rem;
  margin-bottom: 1rem;
  display: block;
}

.upload-text {
  color: #6b7280;
}

.selected-files {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.file-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem;
  background: #f3f4f6;
  border-radius: 8px;
}

.file-name {
  flex: 1;
  color: #4b5563;
  font-size: 0.9rem;
}

.remove-file {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: none;
  background: #dc2626;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
}

.remove-file:hover {
  background: #b91c1c;
}

.form-footer {
  padding: 1.5rem;
  border-top: 1px solid #e5e7eb;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  background: #f8f9fa;
}

.submit-btn, .cancel-btn {
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  border: none;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.2s;
}

.submit-btn {
  background: #2196F3;
  color: white;
}

.submit-btn:hover {
  background: #1976D2;
}

.cancel-btn {
  background: #f3f4f6;
  color: #4b5563;
}

.cancel-btn:hover {
  background: #e5e7eb;
}

.btn-icon {
  font-size: 1.1rem;
}

/* Project List Styles */
.project-list-content {
  padding: 1.5rem;
}

.project-items {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.project-item {
  padding: 1.25rem;
  border-radius: 12px;
  background: #f9fafb;
  cursor: pointer;
  transition: all 0.2s;
  border: 1px solid #e5e7eb;
}

.project-item:hover {
  background: #f0f9ff;
  border-color: #2196F3;
  transform: translateY(-2px);
}

.project-info h4 {
  margin: 0 0 0.5rem 0;
  color: #1a365d;
  font-size: 1.1rem;
}

.project-meta {
  display: flex;
  gap: 1rem;
  color: #6b7280;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.project-description {
  margin: 0;
  color: #4b5563;
  font-size: 0.9rem;
  line-height: 1.5;
}

.loading-state, .empty-state {
  text-align: center;
  padding: 3rem;
  color: #6b7280;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 3px solid #f3f4f6;
  border-top-color: #2196F3;
  border-radius: 50%;
  margin: 0 auto 1rem;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

@keyframes modalSlideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Progress Bar Styles */
.upload-progress {
  margin-top: 1rem;
}

.progress-bar {
  height: 6px;
  background: #e5e7eb;
  border-radius: 3px;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  background: #2196F3;
  transition: width 0.3s ease;
}

.progress-text {
  color: #4b5563;
  font-size: 0.9rem;
  margin-top: 0.5rem;
  display: block;
}
</style>
